<template>

<app-wrapper class="paginate">

	<app-wrapper :content="true" class="paginate-content">

		<div class="paginate-button" v-on:click="$emit('click')"><i class="fa fa-spinner fa-spin" v-if="loading" /><template v-if="!loading">Load more</template></div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

	props: ['loading']

}

</script>

<style scoped>

.paginate {
	padding: 0px 0px 64px 0px;
}

.is-tablet .paginate {
	padding-bottom: 40px;
}

.paginate-content {
	display: flex;
	justify-content: center;
}

.paginate-button {
	height: 56px;
	width: 200px;
	border-radius: 36px;
	font-size: 16px;
	font-weight: 500;
	color: #112A45;
	user-select: none;
	text-align: center;
	line-height: 56px;
	background-color: #BBD9CD;
	cursor: pointer;
}

.is-tablet .paginate-button,
.is-mobile .paginate-button {
	height: 48px;
	line-height: 48px;
	font-size: 14px;
}

.is-desktop .paginate-button:hover {
	background-color: #112a45;
	color: #fff;
}

</style>
